// Import polyfill from node_modules
// import 'core-js/modules/es6.set.js'
// import 'core-js/modules/es6.array.find-index.js'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'

(function() {
	gsap.registerPlugin(ScrollTrigger)

	const useHeroSwiper = () => {
		const swiperName = '.hero__swiper'
		const swiperEl = document.querySelector(swiperName)
		if (!swiperEl) return

		const options = {
			speed: 800,
			loop: true,
			parallax: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false
			},
			navigation: {
				prevEl: `${swiperName}__button--prev`,
				nextEl: `${swiperName}__button--next`,
			},
			pagination: {
				el: `${swiperName}__pagination`,
				clickable: true
			}
		}

		new Swiper(swiperEl, options)
	}

	const useSolutionSwiper = () => {
		const swiperName = '.solution__swiper'
		const swiperEl = document.querySelector(swiperName)
		if (!swiperEl) return;

		const options = {
			speed: 600,
			loop: true,
			slidesPerView: 1,
			spaceBetween: 30,
			centeredSlides: true,
			breakpoints: {
				768: {
					slidesPerView: 3,
					spaceBetween: 30,
				},
				1024: {
					slidesPerView: 4,
					spaceBetween: 30,
				},
				1280: {
					slidesPerView: 5,
					spaceBetween: 30,
				},
			},
			pagination: {
				el: `${swiperName}__pagination`,
				clickable: true
			},
			navigation: {
				prevEl: `${swiperName}__button--prev`,
				nextEl: `${swiperName}__button--next`,
			}
		}

		new Swiper(swiperEl, options)
	}

	const useNewsBgParallax = () => {
		const target = document.querySelector('.parallax-image')
		if (!target) return;

		const trigger = target.parentNode
		target.style.setProperty('height', '120%')

		gsap
			.timeline({
				scrollTrigger: {
					trigger,
					start: 'top bottom',
					end: 'bottom top',
					scrub: true,
					// markers: true,
				}
			})
			.fromTo(target, {
				y: '-20%'
			}, {
				y: '0%'
			})
	}

	document.addEventListener('DOMContentLoaded', () => {
		useHeroSwiper()
		useSolutionSwiper()
		useNewsBgParallax()
	})
})();
